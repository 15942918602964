import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import { appURLs } from "@/app/app-urls.mjs";
import type { Rarity } from "@/game-fortnite/components/ItemBox";
import { ItemBox, ItemContainer } from "@/game-fortnite/components/ItemBox";
import { rarityKeysByRank } from "@/game-fortnite/constants/rarities.mjs";
import type { WeaponItem } from "@/game-fortnite/models/items.mjs";
import getWeaponKey from "@/game-fortnite/utils/get-weapon-key.mjs";
import GameGuideTable from "@/marketing/GameGuideTable.jsx";
import GameGuideTileLg from "@/marketing/GameGuideTileLg.jsx";
import { getLocale } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const COLS = [
  {
    i18n: ["common:weapon", "Weapon"],
    dataKey: "weapon",
  },
  {
    i18n: ["fortnite:stats.dps", "DPS"],
    dataKey: "dps",
  },
  {
    i18n: ["fortnite:stats.damage", "Damage"],
    dataKey: "damage",
  },
  {
    i18n: ["fortnite.stats.magSize", "Mag. Size"],
    dataKey: "magazineSize",
  },
];

interface Weapon {
  id: string;
  imageKey: string;
  name: string;
  rarity: Rarity;
  dps: number;
  damage: number;
  magazineSize: number;
  link: string;
}

function toWeaponRow(weapon: WeaponItem): Weapon | undefined {
  const [rarity, stats] =
    Object.entries(weapon.stats).sort(
      ([rarity1], [rarity2]) =>
        (rarityKeysByRank[rarity2.toLowerCase()] || 0) -
        (rarityKeysByRank[rarity1.toLowerCase()] || 0),
    )[0] || [];
  if (rarity) {
    return {
      id: weapon.ids[0],
      imageKey: weapon.icon,
      name: weapon.displayName,
      rarity: rarity.toUpperCase() as Rarity,
      dps:
        stats.damagePerBullet *
        (stats.firingRate || 1) *
        (stats.bulletsPerCartridge || 1),
      damage: stats.damagePerBullet,
      magazineSize: stats.clipSize,
      link: `/fortnite/database/weapon/${getWeaponKey(weapon.displayName)}`,
    };
  }
}

export default function HomeDatabaseTile() {
  const { t } = useTranslation();

  const {
    fortnite: { active },
  } = useSnapshot(readState);

  const data = useMemo(() => {
    const weapons = [];
    for (const category in active) {
      let topCategoryWeapon;
      for (const weapon of Object.values(active[category])) {
        const weaponRow = toWeaponRow(weapon as WeaponItem);
        if (!topCategoryWeapon || topCategoryWeapon.dps < weaponRow.dps) {
          topCategoryWeapon = weaponRow;
        }
      }
      if (topCategoryWeapon) weapons.push(topCategoryWeapon);
    }
    return weapons.sort((a, b) => b.dps - a.dps);
  }, [active]);

  return (
    <GameGuideTileLg
      title={["common:navigation.database", "Database"]}
      description={[
        "home:guides.fortnite.database.description",
        "Discover which Weapons perform best with their up-to-date stats like DPS, damage and other helpful statistics.",
      ]}
      buttonText={["common:navigation.viewWeapons", "View Weapons"]}
      href={"/fortnite/database/weapons/active"}
    >
      <GameGuideTable className={Table()}>
        <GameGuideTable.Header>
          <div className="weapon-meta">{t(COLS[0].i18n)}</div>
          <div className="stat">{t(COLS[1].i18n)}</div>
          <div className="stat">{t(COLS[2].i18n)}</div>
          <div className="stat">{t(COLS[3].i18n)}</div>
        </GameGuideTable.Header>
        <GameGuideTable.Body numRows={5}>
          {(data || []).map((row, i) => (
            <GameGuideTable.Row key={i} link={row.link}>
              <div className={`weapon-meta type-subtitle--bold`}>
                <ItemContainer>
                  <ItemBox
                    id={row.id}
                    name={row.name}
                    rarity={row.rarity.toUpperCase() as Rarity}
                    src={`${appURLs.CDN}/fortnite/images/${row.imageKey}.webp`}
                  />
                  <p className="type-callout--bold shade0 name">{row.name}</p>
                </ItemContainer>
              </div>
              <div className="stat type-body2-form--bold">
                {row.dps?.toLocaleString(getLocale(), {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 1,
                }) ?? "-"}
              </div>
              <span className="stat type-body2-form--bold">
                {row.damage?.toLocaleString(getLocale()) ?? "-"}
              </span>
              <span className="stat type-body2-form--bold">
                {row.magazineSize?.toLocaleString(getLocale()) ?? "-"}
              </span>
            </GameGuideTable.Row>
          ))}
        </GameGuideTable.Body>
      </GameGuideTable>
    </GameGuideTileLg>
  );
}

const Table = () => css`
  .weapon-meta {
    flex: 1;
    display: flex;
    align-items: center;
    gap: var(--sp-3);
    color: var(--shade0);
  }
  .weapon-img {
    width: 4.5rem;
    height: auto;
    aspect-ratio: 350 / 197;
    border-radius: var(--br-sm);
  }
  .weapon-name {
    max-width: 11ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .stat {
    display: flex;
    justify-content: center;
    width: 17%;
    white-space: nowrap;
  }
  .tier svg {
    width: var(--sp-6);
    height: var(--sp-6);
  }
`;
